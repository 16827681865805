import { template as template_20841818a2b14c209734f1ce1db3872e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_20841818a2b14c209734f1ce1db3872e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
